import React, { useCallback, useEffect, useRef, useState } from 'react';


import { useClassnames } from '../../../../hooks/use-classnames';
import { IRespondForm } from '../../../../types/strapi/global';
import { formatText } from '../../../../utils';
import Alert from '../../../alert';
import ContactForm from '../../../ContactForm';
import GridWrapper from '../../../grid-wrapper';
import Heading from '../../../heading';

import './index.css';

import { useAppContext } from '../../../../context/context';

const rootClassName = 'question-block';

interface IProps {
    respondForm: IRespondForm,
    formHeader: string
}

const QuestionBlock = ({ respondForm, formHeader }: IProps) => {
    const cn = useClassnames();
    const { isVisibleFeedbackForms } = useAppContext();

    const {
        errorContactSend,
        successContactSend
    } = respondForm;

    const [isFormError, setIsFormError] = useState(false);
    const [isFormSended, setIsFormSended] = useState(false);
    const [isFormResetted, setIsFormResetted] = useState(false);

    useEffect(() => {
        return () => setIsFormResetted(false);
    }, []);

    const timeoutId = useRef<ReturnType<typeof setTimeout>>();
    const timeoutTime = 3000;

    const handleFormSubmitError = () => {
        setIsFormSended(true);
        setIsFormError(true);
        setIsFormResetted(true);
    };

    const handleFormSubmitSuccess = () => {
        setIsFormSended(true);
        setIsFormError(false);
        setIsFormResetted(true);

        timeoutId.current = setTimeout(() => {
            setIsFormSended(false);
            setIsFormResetted(false);
        }, timeoutTime);
    };

    const handleResetFormState = useCallback((e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e?.stopPropagation();

        if(timeoutId) {
            clearTimeout(timeoutId.current as ReturnType<typeof setTimeout>);
        }

        setIsFormSended(false);
        setIsFormError(false);
        setIsFormResetted(false);
    }, [timeoutId]);

    if(!isVisibleFeedbackForms) {
        return null;
    }

    return (
        <GridWrapper className={cn(rootClassName)}>
            <Heading
                className={cn(`${rootClassName}__title`)}
                level={3}
                dangerouslySetInnerHTML={{ __html: formatText(formHeader) }}
            />
            {isVisibleFeedbackForms && <ContactForm
                data={respondForm}
                className={cn(`${rootClassName}__contact-form`)}
                isFileButtonContrast={true}
                onFormSubmitError={handleFormSubmitError}
                onFormSubmitSuccess={handleFormSubmitSuccess}
                isFormResetted={isFormResetted}
            />}
            <Alert
                type={isFormError ? 'error' : 'success'}
                onCloseClick={handleResetFormState}
                isVisible={isFormSended}
            >
                {isFormError ? errorContactSend : successContactSend}
            </Alert>
        </GridWrapper>
    );
};

export default QuestionBlock;
