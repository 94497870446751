import React from 'react';

import { useClassnames } from '../../../../../../hooks/use-classnames';
import { IContactsPage } from '../../../../../../types/strapi/contacts';
import GridWrapper from '../../../../../grid-wrapper';

import ContactItem from './components/ContactItem';
import { IContactLink } from './types';

import './index.css';


const rootClassName = 'feedback-links';

interface IProps {
    data: Pick<IContactsPage, 'aboutText' | 'cities' | 'prEmailLabel' | 'prEmail' | 'commercialProposalsEmailLabel' | 'commercialProposalsEmail' | 'socialNetworkLabel' | 'socialNetwork'>
}

const FeedbackLinks = ({ data }: IProps) => {
    const cn = useClassnames();

    const socialLinks: Array<IContactLink> = data.socialNetwork.map(({ text, to }) => {
        return {
            name  : text,
            href  : to,
            target: '_blank'
        };
    });

    const forSmi: Array<IContactLink> = [{
        name: data.prEmail,
        href: `mailto:${data.prEmail}`
    }];

    const forPartners: Array<IContactLink> = [{
        name: data.commercialProposalsEmail,
        href: `mailto:${data.commercialProposalsEmail}`
    }];

    return (
        <GridWrapper className={cn(rootClassName)}>
            <div className={cn(`${rootClassName}__block`)}>
                <ContactItem title={data.socialNetworkLabel} links={socialLinks} />
            </div>
            <div className={cn(`${rootClassName}__block`)}>
                <ContactItem title={data.prEmailLabel} links={forSmi} />
            </div>
            <div className={cn(`${rootClassName}__block`)}>
                <ContactItem title={data.commercialProposalsEmailLabel} links={forPartners} />
            </div>
        </GridWrapper>
    );
};

export default FeedbackLinks;
