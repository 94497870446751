import React from 'react';

import { useClassnames } from '../../../../hooks/use-classnames';
import { IFooter } from '../../../../types/strapi/global';
import FooterDisclamer from '../../../FooterDisclamer';

import './index.css';

const rootClassName = 'contact-footer';

interface IProps {
    data: IFooter
}

const ContactFooter = ({ data }: IProps) => {
    const cn = useClassnames();
    return (
        <footer className={cn(rootClassName)}>
            <FooterDisclamer disclamerClassName={`${rootClassName}__disclaimer`} data={data} />
        </footer>
    );
};

export default ContactFooter;
