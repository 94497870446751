import React from 'react';

import { useClassnames } from '../../hooks/use-classnames';
import { IContactsPage } from '../../types/strapi/contacts';
import { IGlobalServerRuntimeData } from '../../types/strapi/global';
import { mediaUrl } from '../../utils';
import Heading from '../heading';
import Layout from '../layout';
import PageSection from '../PageSection';

import ContactFooter from './components/ContactFooter';
import Contacts from './components/Contacts';
import QuestionBlock from './components/QuestionBlock';

import './index.css';

const rootClassName = 'contacts-page';

interface IQueryData extends IGlobalServerRuntimeData {
    contactsPage: IContactsPage
}

interface IProps {
    data: IQueryData
}

const ContactsPage = ({ data }: IProps) => {
    const cn = useClassnames();

    const { seo, headerText } = data.contactsPage;

    return (
        <Layout
            seo={{
                ...seo,
                shareImage: seo?.shareImage?.url ? mediaUrl(seo?.shareImage?.url) : undefined
            }}
            hasContactButton={false}
            navPanel={data.navPanel}
            global={data.global}
            respondForm={data.respondForm}
        >
            <PageSection removeBelowOffset={true} className={cn(`${rootClassName}__section`)}>
                <Heading level={1} as="h1">{headerText}</Heading>
            </PageSection>
            <PageSection className={cn(`${rootClassName}__section`)}>
                <Contacts data={data.contactsPage} />
            </PageSection>
            <PageSection className={cn(`${rootClassName}__section ${rootClassName}__section-question`)}>
                <QuestionBlock respondForm={data.respondForm} formHeader={data.contactsPage.formHeader} />
            </PageSection>
            <PageSection removeBelowOffset={true}>
                <ContactFooter data={data.footer} />
            </PageSection>
        </Layout>
    );
};

export default ContactsPage;
